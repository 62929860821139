import Earn_in_3Steps_1 from "../public/assets/json/InstallAndRegister_Step1.json";
import Earn_in_3Steps_2 from "../public/assets/json/TrainingAndShare_Step2.json";
import Earn_in_3Steps_3 from "../public/assets/json/StartEarning_Step3.json";
import EarnMoreicon from "../public/assets/images/home/EarnMoreicon.webp";
import wfhIcon from "../public/assets/images/home/wfhIcon.webp";
import zeroInvestmentIcon from "../public/assets/images/home/zeroInvestmentIcon.webp";
import InstantPayoutIcon from "../public/assets/images/home/InstantPayoutIcon.webp";
import EducationalContentIcon from "../public/assets/images/home/EducationalContentIcon.webp";
import Earn from "../public/assets/images/home/Earn.webp";
import EducationalContent from "../public/assets/images/home/EducationalContent.webp";
import InstantPayout from "../public/assets/images/home/InstantPayout.webp";
import WorkAnywhere from "../public/assets/images/home/WorkAnywhere.webp";
import ZeroInvestment from "../public/assets/images/home/ZeroInvestment.webp";
import SavingAccount from "../public/assets/images/home/SavingAccount.webp";
import CreditCard from "../public/assets/images/home/CreditCard.webp";
import DematAccount from "../public/assets/images/home/DematAccount.webp";
import Credit from "../public/assets/images/home/Credit.webp";
import Loan from "../public/assets/images/home/Loan.webp";
import Investment from "../public/assets/images/home/Investment.webp";
import savingAccountIcon from "../public/assets/images/home/savingAccountIcon.webp";
import dematAccountIcon from "../public/assets/images/home/dematAccountIcon.webp";
import creditCardIcon from "../public/assets/images/home/creditCardIcon.webp";
import loanIcon from "../public/assets/images/home/loanIcon.webp";
import creditIcon from "../public/assets/images/home/creditIcon.webp";
import investmentIcon from "../public/assets/images/home/investmentIcon.webp";
import BajajFinserv from "../public/assets/images/home/BajajFinserv.webp";
import paisaLogo from "../public/assets/images/home/5paisaLogo.webp";
import axis_bankLogo from "../public/assets/images/home/axis_bankLogo.webp";
import freechargeLogo from "../public/assets/images/home/freechargeLogo.webp";
import idfc_firstLogo from "../public/assets/images/home/idfc_firstLogo.webp";
import indiaGoldLogo from "../public/assets/images/home/indiaGoldLogo.webp";
import jupiterLogo from "../public/assets/images/home/jupiterLogo.webp";
import mudrexLogo from "../public/assets/images/home/mudrexLogo.webp";
import paytmmoneyLogo from "../public/assets/images/home/paytm-moneyLogo.webp";
import upstoxLogo from "../public/assets/images/home/upstoxLogo.webp";
import indialends from "../public/assets/images/home/indialends.webp";
import induslndbank from "../public/assets/images/home/induslndbank.webp";
import instamoney from "../public/assets/images/home/instamoney.webp";
import abhiLoansLogo from "../public/assets/images/home/abhiLoansLogo.webp"
import media_1 from "../public/assets/images/media/media_1.webp";
import media_2 from "../public/assets/images/media/media_2.webp";
import media_3 from "../public/assets/images/media/media_3.webp";
import media_4 from "../public/assets/images/media/media_4.webp";
import media_5 from "../public/assets/images/media/media_5.webp";
import media_6 from "../public/assets/images/media/media_6.webp";
import media_7 from "../public/assets/images/media/media_7.webp";
import media_8 from "../public/assets/images/media/media_8.webp";
import media_9 from "../public/assets/images/media/media_9.webp";
import media_10 from "../public/assets/images/media/media_10.webp";
import media_11 from "../public/assets/images/media/media_11.webp";
import media_12 from "../public/assets/images/media/media_12.webp";
import media_13 from "../public/assets/images/media/media_13.webp";
import media_14 from "../public/assets/images/media/media_14.webp";
import media_15 from "../public/assets/images/media/media_15.webp";
import media_16 from "../public/assets/images/media/media_16.webp";
import media_17 from "../public/assets/images/media/media_17.webp";

import investor1 from "../public/assets/images/home/more/about-us/investor1.webp";
import investor2 from "../public/assets/images/home/more/about-us/investor2.webp";
import investor3 from "../public/assets/images/home/more/about-us/investor3.webp";
import investor4 from "../public/assets/images/home/more/about-us/investor4.webp";
import investor5 from "../public/assets/images/home/more/about-us/investor5.webp";
import investorImg1 from "../public/assets/images/home/more/about-us/investorImg1.webp";
import investorImg2 from "../public/assets/images/home/more/about-us/investorImg2.webp";
import investorImg3 from "../public/assets/images/home/more/about-us/investorimg3.webp";
import investorImg4 from "../public/assets/images/home/more/about-us/investorImg4.webp";
import investorImg5 from "../public/assets/images/home/more/about-us/investorImg5.webp";
import investorImg6 from "../public/assets/images/home/more/about-us/investorImg6.webp";
import investorImg7 from "../public/assets/images/home/more/about-us/investorImg7.webp";
// import AnshumanSahay from "../public/assets/images/home/more/about-us/AnshumanSahay.webp";
// import AshishArora from "../public/assets/images/home/more/about-us/AshishArora.webp";
// import RajatGupta from "../public/assets/images/home/more/about-us/RajatGupta.webp";
// import ManishJain from "../public/assets/images/home/more/about-us/ManishJain.webp";
import DarpanKhurana from "../public/assets/images/home/more/about-us/DarpanKhurana.webp";
// import AmitJoshi from "../public/assets/images/home/more/about-us/AmitJoshi.webp";
// import AnandSaxena from "../public/assets/images/home/more/about-us/AnandSaxena.webp";
// import AnkitAwasthi from "../public/assets/images/home/more/about-us/AnkitAwasthi.webp";
// import AtulChoudary from "../public/assets/images/home/more/about-us/AtulChoudary.webp";
// import JayaSingh from "../public/assets/images/home/more/about-us/JayaSingh.webp";
import Ankit from "../public/assets/images/home/more/about-us/Ankit.webp";
// import dummyImg from "../public/assets/images/home/more/about-us/dummyImg.webp";
// import omkar from "../public/assets/images/home/more/about-us/omkar.webp";
// import RajenderArya from "../public/assets/images/home/more/about-us/RajenderArya.webp";
// import RajeshGupta from "../public/assets/images/home/more/about-us/RajeshGupta.webp";

export const PRIVACY_LIST: {
  title: string;
  desc: {
    text: string;
    points?: string[];
    pointsType?: "a" | "i" | "1" | "A" | "I";
  }[];
  textType?: "a" | "i" | "1" | "A" | "I";
}[] = [
    {
      title: "1. INTRODUCTION",
      desc: [
        {
          text: `Vitrak Technologies Private Limited (hereinafter referred to as “Company” or “GroMo” or “We”) including its mobile app and webpages (collectively referred to as “Platform”) in the name “GroMo.in” is owned by its parent company GroMo INC, is committed to maintain the confidentiality, integrity and security of all information of its Users. This Policy details the practices we follow to protect your privacy, including how we collect, use, disclose, and secure your data. The objective of this document is to ensure transparency in our data handling practices and to affirm our dedication to data protection and privacy in compliance with applicable laws and regulations. By using our services, you agree to the collection and use of information in accordance with this policy. We encourage you to read this document thoroughly to understand our approach to privacy and how it affects you as a user of our services.`,
        },
      ],
    },
    {
      title: "2. ABOUT THE COMPANY",
      desc: [
        {
          text: "GroMo is a fintech company which is, inter-alia, engaged in the business of generating customer leads via its registered agents referred as GroMo Partner, through its webpage(s) namely www.GroMo.in, www.indiasales.club and its sub-domains in order to promote, publish and market he banking and financial products. It enables agents to sell various financial and non-financial products and services such as Demat account, credit cards, loans, saving accounts etc, through the platform and earn money (“Services”).",
        },
        {
          text: "Our Platform is primarily available for use within India only. Your continued use of our Platform is an affirmation that you have read the Privacy Policy, understood it, assent to and provide consent to our use of your information (including sensitive personal information as defined under the Information Technology Act 2000, applicable rules, notification etc.), including subsequent changes and amendments by the Company at its sole discretion.",
        },
        {
          text: "Our platform is intended for use only by individuals who are 18 years of age or older. By using our services, you confirm that you meet the minimum age requirement.",
        },

      ],
    },
    {
      title: "3. PERMISSION & WARRANTY",
      desc: [
        {
          text: "Our platform is intended for use only by individuals who are 18 years of age or older. By using our services, you confirm that you meet the minimum age requirement. By uploading or making your personal information, materials, or content available on GroMo, you grant us a perpetual, royalty- free, non-exclusive right and license to use, reproduce, modify, publish, distribute, display, perform, and transmit such content. You warrant that you have the legal right to grant these permissions, and that the owner of the content has explicitly given GROMO the same rights.",
        },
        {
          text: "You also warrant that all materials you provide do not infringe on any copyrights, violate any property rights, or contain any scandalous, libellous, or unlawful content. This applies specifically to user-generated content as described. Any personal information you provide, whether during registration or as part of a purchase process, is protected under our Privacy Policy.",
        },
      ],
    },
    {
      title: "4. WHAT INFORMATION DO WE COLLECT?",
      desc: [
        {
          text: "We collect your Personal Information when you register or set up an account with us on our Platform. When you start using the Services, we ask you to provide certain information as part of the registration process, and in the course of your interface with the Platform.",
        },
        {
          text: "We collect your Personal Information when you register or set up an account with us on our Platform. When you start using the Services, we ask you to provide certain information as part of the registration process, and in the course of your interface with the Platform.",
        },
        {
          text: "Furthermore, we occasionally obtain information about you from external sources and incorporate this into your existing account data to enhance and personalize the services we provide to you.",
        },
      ],
    },
    {
      title: "5. HOW DO WE USE YOUR INFORMATION?",
      desc: [
        {
          text: "We collect your personal information when you create an account, use our products or services, or visit pages on our website. We use your contact number to send SMS notifications and information about our services to your mobile device. By registering, you authorize us and our business partners and affiliates to send you text, notifications and email alerts, including your login details, service updates, and promotional messages. This authorization remains effective until you deactivate your account or withdraw your consent. For the purpose of onboarding or fulfilling Know Your Customer (KYC) requirements, we may temporarily access and store data from your camera, microphone, location, and mobile device. This is done only with your explicit consent and in compliance with the Reserve Bank of India’s Digital Lending Guidelines, as amended from time to time.",
        },
      ],
    },
    {
      title: "6. PURPOSE OF COLLECTING INFORMATION",
      desc: [
        {
          text: "We use the Information provided by you including but not limited to following purposes:",
          points: [
            "To create your account, verify your identity and process your transaction(s);",
            "To fulfil your requests for products and services and provide information about online and offline special promotional offers, products, services and/or updates;",
            "To respond to your queries on raised on the Platform;",
            "For cross-platform data sharing to improve the user experience;",
            "To customise the advertising and content that is seen on our Platform;",
            "To allow our business partners and/or affiliated entities to present customised communications to you;",
            "For personalized user experience and analytics to improve our services.",
            "To derive statistics about your usage of Platform and its effectiveness;",
            "File storage permissions on android/iOS and Website for uploading customer documents are obtained for the purpose of processing customer applications by our Partners.",
            "To resolve disputes, customer support and troubleshoot problems that arise from using the Platform;",
            "To detect, prevent and protect GroMo from any errors, frauds, mis-selling, wilful misconduct, and other criminal or prohibited activity on our Platform;",
            "To enforce and inform the users about our Terms of Service;",
            "To communicate important notices or changes in the services offered by GroMo that governs the relationship between you and GroMo along with our affiliates; and",
            "For such other purposes, for which you may have granted permission to use such information, based on your interaction with the Platform.",
          ],
          pointsType:"i"
        },
      ],
    },
    {
      title: "7. TO WHOM DO WE DISCLOSE YOUR INFORMATION?",
      desc: [
        {
          text: "We may share your information with outsiders who assist us in operating our business, including but not limited to payment processors and marketing agencies. We require all such parties mentioned below to respect the security of your personal data and to treat it in accordance with the law:",
          points: [
            "Third-Party Products/Services: Numerous third-party products/services including but not limited to affiliates, authorized payment gateways, and payment processors are involved in various functions such as billing, content provision, customer support and product supply such as loans, credit cards, savings & demat accounts and mutual funds offered. If you choose to apply for these products or services, your information disclosed to these providers will also be governed by their privacy policies in addition to ours. We encourage you to read the privacy policies of these third-party providers.",
            `Corporate Entities and Affiliates: Your personal information may be shared with our other corporate entities and affiliates to help detect and prevent identity theft, fraud, and other potentially illegal acts; to facilitate joint or co-branded services that you request where such services are provided by more than one corporate entity; and to support the overall operation of our business and the Site. For the purposes of this policy, an "Affiliate" refers to any entity that, directly or indirectly, controls, is controlled by, or is under common control with GroMo. “Control” means the possession, directly or indirectly, of the power to direct or cause the direction of the management or policies of an entity, whether through ownership of securities, by contract, or otherwise.`,
            "Legal and Regulatory Disclosures: We may disclose your information without obtaining prior written consent to government agencies mandated under the law for verification of identity, or for the prevention, detection, investigation, prosecution, and punishment of offenses, or where disclosure is necessary for compliance with a legal obligation.",
            "Successor-in-Interest: Your personally identifiable information may be transferred to a cessor-in-interest in the event of a sale, merger, reorganization, consolidation, dissolution, insolvency, or similar events involving GroMo, where GroMo is not the surviving entity.",
            "Anonymous and Aggregate Information: We limit the collection and use of your personal information. We may make and disclose anonymous or aggregate personal information in a non-personally identifiable manner. Such information does not identify you individually. Access to your account information and other personal identifiable information is strictly restricted and used only in accordance with specific internal procedures and for the purposes set out in this Privacy Policy.",
            `Advertising and Promotional Disclosures: On occasion, we may provide magazine subscribers' postal mailing addresses to selected advertisers who wish to reach you by mail, provided you have given us your permission for such disclosures.`,
            "Protection of Rights and Safety: We may disclose personal information to protect the rights, property, and safety of GroMo, our business and services partners, content partners, and users. This includes enforcing our Terms of Service and taking necessary actions in urgent circumstances.",
          ],
          pointsType:"i"
        },
      ],
    },
    {
      title: "8. THIRD PARTY WEBLINKS, APPS AND PLUG-INS",
      desc: [
        {
          text: `Our platform may contain links to websites, apps, and plugins operated by third parties ("Third-Party Sites"). These Third-Party Sites may collect personal information, and GroMo does not control and is not responsible for their privacy practices, content, or security measures. Each Third-Party Site operates under its own privacy policy, which may differ from ours. We strongly advise you to review the privacy policies of any Third-Party Sites you visit to understand how your information may be used and protected.`,
        },
        {
          text: "GroMo is not liable for the privacy practices or the content of any Third-Party Sites. We do not endorse and are not responsible for any information that may be collected through these Third-Party Sites. We are committed to ensuring that your personal and non-personal information is accessed only by authorized personnel who need it to perform their duties, and by third parties who have a legitimate purpose for accessing it and have obtained your consent. We transfer information only to Third-Party Sites that offer a comparable level of data protection as mandated by the IT Act and the SDPI Rules.",
        }
      ],
    },
    {
      title: "9. OUR COOKIE POLICY",
      desc: [
        {
          text: `A "cookie" is a small piece of data stored by a web server on your web browser. It is used to maintain session information between your browser and our server, allowing us to remember specific information related to your interactions with our platform. Cookies can enhance your online experience by automatically recalling personalized settings during your visits. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website. If you choose to decline cookies, you may also receive alerts when cookies are being sent to your device.`,
        },
        {
          text: "We also allow third-party companies, including advertising partners, to set and access their own cookies on your computer. These cookies are governed by their own privacy policies, and GroMo does not have access to these cookies. The use of cookies by these third-party advertisers or analytics providers is not covered by our privacy policy and is subject to their own privacy policies.",
        },
        {
          text: "By using our platform, you consent to the use of cookies as described in this policy. Note that our cookies do not access personal information stored on your device, nor do they compromise the security of your data.",
        }
      ],
    },
    {
      title: "10. HOW DO WE RETAIN YOUR INFORMATION?",
      desc: [
        {
          text: "Your Personal Information and Non-Personal Information are retained:",
          points:[
            "As long as your account is active or as needed to provide our services.",
            "To comply with our legal obligations, resolve disputes, or for other essential business purposes.",
            "For a period of 5 years after you deactivate or unsubscribe from your account with us, in line with our business practices.",
          ],
          pointsType:"A"
        },
        {
          text:"There are specific scenarios where we may retain your information for extended periods:",
          points:[
            "Legal Proceedings and Investigations: If required by law or for legal proceedings, investigations, or inquiries before courts, tribunals, forums, or commissions.",
            "Service Enhancement: To improve and enhance our products and services.",
            "Contractual Obligations: As required under agreements with our business partners."
          ],
           pointsType:"A"
        }
      ],
    },
    {
      title: "11. DATA PROTECTION RIGHTS",
      desc: [
        {
          text: "",
          points:[
            "Withdrawing Consent: At any time during your use of our Services, you have the right to withdraw the consent you previously provided for the collection and use of your sensitive personal data or information. To withdraw your consent, please send a written request to support@gromo.in. If you withdraw consent, we may have to restrict access to our platform or services and potentially remove your data or de-identify it, ensuring it cannot be traced back to you. Upon receiving your request to withdraw consent, we will verify your identity and process the request within 45 working days from the date of its receipt, subject to successful verification.",
            "Deletion of Data: Should you wish to have your data deleted, please send a request to support@gromo.in. If you request the deletion or removal of your personal data where you believe we no longer have the right to process it, we will comply with your request unless there are overriding legal obligations. For example, if you have availed services through us, we may be required to retain your data as per applicable laws to continue providing services or for our partner banks/NBFCs to fulfill their service obligations. Rest assured, any retained information will be protected in accordance with all applicable cybersecurity norms. Note that deletion requests may be denied under circumstances such as ongoing investigations of Page 5 of 2 fraud, compliance requirements, or other legal obligations which necessitate retaining data for a certain period.",
            "Opt-Out of Direct Marketing: You can deny us the use of your personal information for direct marketing or other promotional activities.",
          ],
          pointsType:"A"
        },
        {
          text:"To exercise any of these rights, please contact us at support@gromo.in. We are committed to responding in accordance with applicable legal standards."
        }
      ],
    },
    {
      title: "12. HOW DO WE SECURE YOUR DATA",
      desc: [
        {
          text: "At GroMo, safeguarding your information is paramount. We employ industry-standard security technologies and practices to protect your data from unauthorized access, use, alteration, or disclosure. These measures include:",
          points:[
            "Robust Security Protocols: Our platform is equipped with advanced security features such as firewalls, intrusion detection systems, and comprehensive access controls. We conduct regular security and penetration tests to fortify our defences.",
            "Data Confidentiality: Access to your personal information is strictly limited to employees who need it to deliver our services or perform their roles. We adhere to stringent physical, electronic, and procedural safeguards in compliance with relevant laws to protect your data.",
            "Data Integrity and Protection: We implement effective data collection, storage, and processing practices alongside robust security measures to guard against unauthorized access or disclosure of your personal information stored on our site.",
            "Transparency in Data Handling: While we strive to ensure the security of our servers and locations, no system is impervious to breaches. In the event of unauthorized access, GroMo disclaims liability. You are also responsible for maintaining the security of your information, including safeguarding your personal and non-personal information.",
            "Consent to Share Information: You expressly agree to the sharing of your personal and payment-related information with third-party service providers, such as payment gateways and processors, as necessary. All payment information transmitted through our platform is protected with industry-standard encryption technologies.",
            "Limitations of Security Guarantees: Despite our stringent measures, we cannot guarantee that data transmission will always be secure. GroMo is not responsible for any disclosure of your information due to transmission errors or unauthorized third-party access beyond our control.",
          ],
          pointsType:"A"
        },{
          text:"We are committed to complying with applicable information security standards. In the event of a suspected data security breach, we will implement our established security protocols and notify all relevant stakeholders, including internal and external parties and statutory authorities, as required by law."
        },{
          text:"Responsibility for Account Security: You are responsible for maintaining the confidentiality and security of your login credentials. Do not share these with any third party."
        }
      ],
    },
    {
      title: "13. HOW DO YOU UPDATE YOUR PERSONAL DATA?",
      desc: [
        {
          text: "we understand the importance of maintaining accurate and current personal information. Here’s how you can update your personal data:",
          points:[
            "Direct Updates: You can update your personal information directly through self-help tools available on our platform. These tools allow you to review and modify your personal data at your convenience.",
            "Email Assistance: If you encounter any issues with updating your information or if specific data fields are locked (e.g., name, Aadhaar number, KYC details), please reach out to us via email at support@gromo.in for assistance.",
            "Locked Information: Once entered on our platform, certain sensitive information like your name and KYC details will be locked in our systems in compliance with legal regulations. For updates involving sensitive financial information such as bank details, additional verification documents may be required."
          ],
          pointsType:"A"
        },
      ],
    },
    {
      title: "14. CHANGE IN PRIVACY POLICY",
      desc: [
        {
          text: "GroMo reserves the right to modify this privacy policy at any time and at its sole discretion. We continuously review and update our privacy practices to ensure they meet legal, technological, and operational changes. We encourage you to review this policy periodically to stay informed about how we are protecting your information.",
        },
      ],
    },
    {
      title: "15. GOVERNING LAW & DISPUTE RESOLUTION",
      desc: [
        {
          text: "The Services provided by GroMo as well as use of our Platform are governed by applicable laws within the territory of India. Any dispute arising from or related to your use of our Platform and/or this Privacy Policy shall be subject to the provisions provided in our Terms of Service.",
        },
      ],
    },

  ];
export const DATA_STORAGE_POLICY: {
  title: string;
  desc: {
    text: string;
    points?: string[];
    pointsType?: "a" | "i" | "1" | "A" | "I";
  }[];
  textType?: "a" | "i" | "1" | "A" | "I";
}[] = [
    {
      title: "",
      desc: [
        {
          text: "All institutional data will be stored, backed-up, archived and disposed of in a manner consistent with its sensitivity, requirements and best practices. Data classification is a key component for making consistent and appropriate decisions related to data storage and retention.",
        },
        {
          text: "Unneeded non-authoritative data (duplicate copies, outdated records, non-business-related files) accumulate in operational locations need to be removed when no longer needed. Purging not only saves IT resources, but also avoids the possibility of compromising sensitive data in these sources that may not be as well protected as the authoritative masters.",
        },
        {
          text: "The purpose of this policy is to direct the implementation of standards and procedures for storing, archiving, and disposing of institutional data. Records Retention Specialist The functional Records Retention Specialist keep abreast of record retention requirements, and advise functional and technical areas about those requirements.",
        },
        {
          text: "Security Assurance Security Assurance reviews and evaluates functional areas for compliance with documented policies and procedures.",
        },
      ],
    },
    {
      title: "Specific Provisions: Data on Protected Storage",
      desc: [
        {
          text: "● Data (Protected Confidential) will be stored only in approved locations and on approved equipment or storage facilities.",
        },
        {
          text: "● On-roll employees should refrain from making duplicate copies or shadow files of authoritative data resources.",
        },
        {
          text: "● Temporary duplicate copies of electronic data created for legitimate reasons must be protected in a like manner to the authoritative data, and removed in a timely manner.",
        },
        {
          text: "● Standards for storing electronic data containing sensitive data should be created and periodically reviewed.",
        },
        {
          text: "● Standards for storing hardcopy containing sensitive data should be created and periodically reviewed.",
        },
        {
          text: "● Periodic reviews should be performed by Security Assurance to ensure compliance with data management policies, standards, and procedures.",
        },
      ],
    },
    {
      title: "Data Backups and Off-site Storage",
      desc: [
        {
          text: "● All data located on our own IT Resources will be backed-up on a regular basis consistent with data classification standards applicable to the data being backed-up.",
        },
        {
          text: "● Backups of data whose loss would impact the operation or viability of the company confidential matters will be taken off-site or written off-site to a secure location in a timely manner.",
        },
        {
          text: "● Any backup media containing confidential data taken off-site or backup data sent off-site will be encrypted.",
        },
      ],
    },
    {
      title: "Data Storage",
      desc: [
        {
          text: "● The need to retain data in locations will be reviewed on an ongoing basis.",
        },
        {
          text: "● Data no longer needed for routine operations, but which must be retained, will be archived in a timely manner.",
        },
        {
          text: "● The management & IT supervisor representative will develop criteria for deciding when data can be archived.",
        },
        {
          text: "● They will also develop procedures for archiving of data.",
        },
      ],
    },
    {
      title: "Data Retention",
      desc: [
        {
          text: "● Data Stewards and Data Managers will be knowledgeable about standards, and procedures regarding retention of data.",
        },
        {
          text: "● Data Managers & Record Retention Specialists will develop procedures to ensure that required data is always accessible, especially as backup media ages, previously supported media is discontinued, supported data formats and standards change, and security controls change.",
        },
      ],
    },
    {
      title: "Data Disposal",
      desc: [
        {
          text: "● The need to retain operational and archived data will be reviewed on an ongoing basis.",
        },
        {
          text: "● Data no longer needed for routine operations and which need not be retained in an archive will be destroyed in a timely manner.",
        },
        {
          text: "● Archived data which need no longer be retained will be destroyed in a timely manner in compliance with State record retention policies.",
        },
        {
          text: "● Data managers in collaboration with functional Record Retention Specialists will develop procedures for disposing of data in compliance with monthly & yearly record retention schedules.",
        },
      ],
    },
    {
      title: "IMPORTANT ADDITIONAL GUIDELINES",
      desc: [
        {
          text: "● Data is stored on paper, it should be kept in a secure place where unauthorized people cannot access it.",
        },
        {
          text: "● These guidelines also apply to data that is usually stored electronically but has been printed out for some reason.",
        },
        {
          text: "● People should make sure paper and printouts are not left where unauthorized people could see them, like on a printer.",
        },
        {
          text: "● Printouts should be shredded and disposed of securely.",
        },
        {
          text: "● When data is stored electronically, it must be protected from unauthorized access, accidental deletion, and malicious hacking attempts.",
        },
        {
          text: "● Data should be protected by AD passwords that are changed on a periodic basis and never shared between employees.",
        },
        {
          text: "● If data is stored on removable media, these should be kept locked away securely when not being used.",
        },
        {
          text: "● Data should only be stored on designated drives and servers.",
        },
        {
          text: "● Servers containing personal data should be sited in a secure location.",
        },
        {
          text: "● Data should be backed up frequently- the backups should be tested regularly, in line with the company’s standard backup procedures-i.e. either in authorized shared drives which can be accessed via company LAN or VPN or on One Drive.",
        },
        {
          text: "● All servers and computers containing data should be protected by approved security software and firewalls.",
        },
      ],
    },
  ];

export const TERM_LIST: {
  title: string;
  desc: {
    text: string;
    points?: string[];
    pointsType?: "a" | "i" | "1" | "A" | "I";
  }[];
  textType?: "a" | "i" | "1" | "A" | "I";
}[] = [
    {
      title: "INTRODUCTION",
      desc: [
        {
          text: "Thank you for using our platform (“Platform”) and Services (as defined below) through our mobile application and website (hereinafter referred to as the “App” or “Website”). Our platform is owned by GroMo Inc. and operated through its subsidiary and Vitrak Technologies Private Limited’s (hereinafter referred collectively to as “GROMO”) platform  These Terms of Service (hereinafter referred to as the “Agreement,” or  “Terms”) apply to access and use of the software including  but not limited to Mobile application, Web Application, and any other relevant medium. This Agreement is a legally binding agreement between you (hereinafter referred to as the “you”, “your”, User(s)”) and GROMO and/or its subsidiaries and affiliates (together referred to as “we”, “us” or “our(s)”), governing the rights and obligations between you and GROMO in respect of the Platform and Services.",
        },
        {
          text: "By accessing and using our Platform, Website or App in any way, including, without limitation, browsing the Website, using any information, using any content, any services, etc., available therein, you agree to and are bound by these Terms of Service. If you do not accept these Terms of Service in full, please cease using our Platform, Website or App immediately, and contact us at support@gromo.in.",
        },
        {
          text: "THESE PLATFORM TERMS HAVE BEEN DRAFTED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 (AS AMENDED) THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS,  PRIVACY POLICY AND TERMS OF SERVICE/ USER AGREEMENT FOR ACCESS OR USAGE OF THE APP AND RELATED SERVICES.",
        },
        {
          text: "THESE TERMS AND THE PLATFORM PRIVACY POLICY ARE COMPLIANT WITH INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OR INFORMATION) RULES, 2011 (AS AMENDED) WHEREVER APPLICABLE  IN ANY MANNER.",
        },
        {
          text: "PLEASE READ THE TERMS CONTAINED IN THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION.",
        },
        {
          text: "BY USING THE PLATFORM BY DOWNLOADING AND INSTALLING THE APP YOU ACCEPT THIS AGREEMENT AND AGREE THAT (A) YOU HAVE READ AND UNDERSTOOD THE AGREEMENT; AND (B) YOU ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS AND THE PLATFORM PRIVACY POLICY, REFERENCED HEREIN.",
        },
        {
          text: "You represent and warrant to us that you will use the Platform in a manner consistent with all Applicable Laws, as defined in Section 2. We reserve the right to refuse access to use the Platform or to terminate access granted to existing Users at any time without according any reasons for doing so.",
        },
      ],
    },
    {
      title: "DEFINITIONS",
      desc: [
        {
          text: "In this Agreement, unless otherwise provides, the following words shall mean:",

          points: [
            "“Applicable Laws” means any applicable Indian statutes, laws, ordinances, regulations, rules, orders, by-laws, administrative interpretation, writ, injunction, directive, judgment or decree or other instruments which has a force of law in India, as is in force from time to time;",
            "“Confidential Data” means any information related to the User, which the User shares with GROMO or submits on the App and/or Website and the proprietary information relation to GROMO or which the User may have come into possession of or pursuant to this Agreement; and",
          ],
          pointsType: "i",
        },
      ],
    },
    {
      title: "THE PLATFORM AND SERVICES",
      desc: [
        {
          text: "GROMO, through its Platform, allows agents to sell various financial and non-financial products and services such as Demat account, credit, loans, saving accounts, etc. to their clients (“User-Clients”), and allows Users to manage money through our Platform (“Services”). All results generated by the Platform is made available on as-is and available basis based on the information you provide us.",
        },
      ],
    },
    {
      title: "TERRITORY",
      desc: [
        {
          text: "Platform, Website, and the App are only available for use and download, within the territory of India. If you have any questions regarding this Section, please email us at support@gromo.in.",
        },
      ],
    },
    {
      title: "PRIVACY POLICY",
      desc: [
        {
          text: "Our Privacy Policy describes how we handle the personal and business information (collectively “Personal Information”) you provide to us when you use or register on the Platform, the Website or the App. You understand that through your use our Platform, Website, or the App, you consent to the collection, processing, and use (as set forth in the Privacy Policy) of this information.",
        },
        {
          text: "Upon your written request, GROMO will provide you with a list of all personal information that we store concerning you within sixty (60) days of receiving your request. Also, upon your prior written request, GROMO will delete any such information within sixty (60) days of receiving your request. Notwithstanding this GROMO can deny such requests, reasonably and in its sole discretion if it suspects fraud, misinformation or in any other situation permitted by law. Further, notwithstanding this if you ask GROMO to delete all such information, we will not be able to continue to provide the access to the Website, App or Platform to you.",
        },
      ],
    },
    {
      title: "ELIGIBILITY & ACCESS RESTRICTIONS",
      desc: [
        {
          text: "To be eligible to use our Platform, Website, or the App, you must meet the following criteria and represent and warrant that you: (1) are not currently restricted from accessing our Platform, Website, or the App, or not otherwise prohibited from having an account, (2) are not our competitor, or are not using our Platform, Website, or the App for reasons that are in competition with us; (3) have full power and authority to enter into this Agreement and doing so will not violate any other agreement to which you are a party; (4) will not violate any of our rights, including intellectual property rights such as patent, copyright, and trademark rights; and (5) agree to provide at your cost all equipment, browser software, and internet access necessary to use our Platform, Website, or the App.",
        },
      ],
    },
    {
      title: "REGISTRATION ON OUR PLATFORM",
      desc: [
        {
          text: "You can access and use the Platform via (i)  the App which can be downloaded and installed from the Google Play Store, or (ii) the Website at (https://www.gromo.in/).",
        },
        {
          text: "By registering on our Platform, you understand that:",
          points: [
            "At the time of registration, you may be required to complete a verification process (“Verification”) as part of setting up your User account, as applicable that would enable you to use the Platform and access our Services (“Account”). Once you have successfully completed the Verification, as applicable, and have set up your Account, you will be given a username and your mobile number will be registered with the Platform using a One-time-Password (“OTP”) (together hereinafter referred to as “Access Credentials”). You are responsible for maintaining the confidentiality of your Access Credentials and are fully responsible for all activities that occur through your Account. Should there be instances of any unauthorized use of your Account or Access Credentials or any other breach of security, you are required to notify GROMO to stop processing requests from your Account, until further instructions.",
            "During the registration process, you may be asked to provide GROMO with yours and/or your User-Client’s mobile numbers, email, and any other information reasonably required to allow GROMO or its subsidiaries, third party partners or financial institutions (“Brand Partners”) to provide you with Services through our Platform.",
            "By registering yours or your User-Client's phone numbers with GROMO, you consent to be contacted by GROMO via phone calls, WhatsApp, SMS notifications or instant messages, in case of verifications and in case of subscription/service/promotional updates. You may opt out of subscription/service/promotional updates upon following due procedure as highlighted by the support personnel over authorized channels.",
            "If you provide GROMO with your User-Client’s mobile number, email id or any other information, you certify and represent that you have their consent and permission to do so under all applicable laws. You further indemnify GROMO from any claims, actions or liabilities arising from your breach of this Section 7.",
            "It is your responsibility to provide a correct mobile number, and email id for yourself and your User-Clients so that GROMO can communicate with via various communication channels. You understand and agree that if GROMO sends a communication to you but you do not receive it because the you have provided a mobile number that is incorrect, out of date or blocked or because  you, or your User-Client, are otherwise unable to receive SMS, GROMO shall be deemed to have provided the communication effectively.",
          ],
          pointsType: "a",
        },
      ],
    },
    {
      title: "USING THE PLATFORM",
      desc: [
        {
          text: "By visiting our website or mobile application, and accessing the information, resources, services, products, and tools we provide for you, either directly or indirectly (hereafter referred to as “Resources”), agree to use these Resources only for the purposes intended as permitted by this Agreement, and/or Applicable Laws.",
        },
        {
          text: "Wherein, you understand that:",
          points: [
            "In order to access our Resources, you may be required to provide certain accurate, corrected and updated information  about yourself as stated and described in our Privacy Policy.",
            "Accessing (or attempting to access) any of our Resources by any means other than through the means we provide is strictly  prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or unconventional means. Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.",
          ],
          pointsType: "a",
        },
      ],
    },
    {
      title: "ACCESS TO PLATFORM, ACCESS RIGHTS AND RESTRICTIONS",
      desc: [
        {
          text: "Subject to your compliance with the terms of this Agreement, we grant you a limited, non-exclusive, revocable, non-transferable, non-licensable, non-sublicensable right  to access and use our Platform, Website, and App strictly in accordance with this Agreement.",
        },
        {
          text: "You will not use, copy, adapt, modify, prepare derivative works based upon our Platform, Website, or the App, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit our Platform, Website, or the App, except as expressly permitted in  these Terms . When accessing our Platform, Website, or the App, you need to make sure that your internet connection is adequate. You are solely responsible for your internet connection including and not limited to the applicable charges, rates, tariffs, and other fees that might apply.",
        },
        {
          text: "YOU AGREE WE ARE NOT  LIABLE FOR ANY DAMAGES OR INJURY RESULTING FROM YOUR ACCESS OR USE OF OUR PLATFORM, WEBSITE, OR THE APP. WE PROVIDE NO WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE OR WARRANTY OF MERCHANTABILITY. THERE IS NO WARRANTY WHICH WILL EXTEND BEYOND THE DESCRIPTION ON THE FACE HEREOF. ANY DAMAGE ALLEGED FOR A LOSS OR INJURY IS LIMITED TO THE FEE, IF ANY, PAID TO GROMO FOR THE ABILITY TO ACCESS OR USE OUR  PLATFORM, WEBSITE, AND/OR THE APP.",
        },
      ],
      textType: "1",
    },
    {
      title: "USER DECLARATION",
      desc: [
        {
          text: "You agree, acknowledge and declare that:",
          points: [
            "you are availing the Services offered on the Platform out of your free will and volition;",
            "the amounts invested on the Platform are through legitimate and bonafide sources only and does not involve and is not designed for the purpose of contravention or evasion of any Applicable Laws;",
            "you shall review and comply with notices sent by GROMO, if any, concerning the Platform and Services;",
            "you shall review the Platform’s Privacy Policy, regularly as may be amended; and",
          ],
          pointsType: "1",
        },
      ],
    },
    {
      title: "RESERVATION OF RIGHTS AND  USER CONDUCT",
      desc: [
        {
          text: "You acknowledge and agree that the Platform is provided for your use. Except to the extent necessary to access and use the Platform, nothing in this Agreement grants any title or ownership interest in or to any copyrights, patents, trademarks, trade secrets or other proprietary rights in or relating to the App/Website and the Platform whether expressly, by implication, by estoppel, or otherwise. GROMO, its licensors, partners, and service providers reserve and will retain its entire right, title, and interest in and to the content on the Platform including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.",
        },
        {
          text: "You may only access and use GROMO for lawful purposes only.  You are responsible for adhering to any and all laws, rules, and regulations that are applicable regardless of any previous knowledge or lack of knowledge about such laws, rules or regulations.",
        },
        {
          text: "Any content uploaded by GROMO on the application and website is for guidance and education purposes only. GROMO does not hold any liability on the accuracy of the content provided. You are responsible to verify each content before sharing, or modifying it.",
        },
        {
          text: "GROMO’s rights and protections as stated in these Terms are equally applicable to any internet service provider that it has selected to host GROMO or any related resources.",
        },
        {
          text: "You expressly agree not to:",
          pointsType: "a",
          points: [
            "duplicate, copy, adapt, modify, prepare derivative works based on the Platform to license, sublicense, publish, broadcast, transmit, distribute, perform, display, sell, rebrand, otherwise transfer or commercially exploit the Platform (excluding any User content);",
            "reverse engineer, download, decompile, disassemble, decipher, capture screen shots, or otherwise attempt to derive the source code for any underlying intellectual property used to provide the Platform or any part thereof;",
            "commit or cause to be committed any criminal offense or encourage conduct that would constitute a criminal offense or give rise to a civil liability, or otherwise violate any local, state, federal or international law or regulation;",
            "upload, post, e-mail or otherwise transmit any content that is unlawful, defamatory, harassing, abusive, invasive of another’s privacy, infringes upon the rights of third parties, harmful, wrongful, or obscene;",
            "harm any minors in any way;",
            "upload, post, e-mail or otherwise transmit any material, information or content that you do not have a right to transmit under law or contractual relationship;",
            "upload, post, email or otherwise transmit any material, information or content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any other individual, party or entity.",
            "disguise the origin of the material, information or content by omitting or obscuring copyright or authorship, or by forging headers or otherwise manipulating identifiers;",
            "upload, post, e-mail or otherwise transmit any unsolicited advertising, promotional materials, or other forms of solicitation, such as “junk mail”, “spam”, “chain letters”, or “pyramid schemes”;",
            "upload, post, e-mail or otherwise transmit any material, information or content that contains software virus or any other computer code, file or program that would interfere with the functionality of any computer software, hardware or telecommunication equipment.",
            "disrupt the normal communication or otherwise act in such a way that would negatively impact other users’ ability to engage in real-time exchanges;",
            "impersonate or claim a relationship with or speak for any individual, business, association, institution, or other organization, including, but not limited to GROMO for which you have no authorization to do so or to claim such a relationship;",
            "interfere with or disrupt the services or servers or networks connected to the services, or disobey any requirements, procedures, policies, or regulations of networks connected to the services and/or this website.",
            "collect or store personal data about other users or User-Clients in a manner incompatible with Applicable Laws;",
            "stalk or otherwise harass any individual;",
            "utilize information, content or any data you view on and/or obtain from the Platform to provide any service that is competitive with us;",
            "rent, lease, loan, trade, sell/re-sell access to the Platform or any information therein, or the equivalent, in whole or part;",
            "use malware or any other means that impair optimal functioning of the App and/or Website;",
            "engage in “framing”, “mirroring”, or otherwise simulating the appearance or function of the Website or App;",
            "attempt to or actually override any security component included in or underlying the App and/or the Platform;",
            "engage in any action that interferes with the proper working of or places an unreasonable load on our infrastructure, including but not limited to unsolicited communications, attempts to gain unauthorised access, or transmission or activation of computer viruses;",
            "remove any copyright, trademark or other proprietary rights notices contained in or on the App and on the Platform including those of both GROMO or any of our partners;",
            "use any information obtained from the App and/or the Platform to harass, abuse or harm another User;",
            "violate the provisions of the Information Technology Act, 2000 and rules and regulations thereunder (as updated) and any applicable data protection laws;",
            "host, display, upload, modify, publish, transmit, update or share any information that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever, or infringes any patent, trademark, copyright or other proprietary rights of a third-party or impersonates another person or threatens public health or safety; promotion of cigarettes or any other tobacco products or consumption of intoxicant including alcohol or threatens critical information infrastructure.",
            "post anything on social media which is detrimental to the reputation of the platform GROMO or to its partner brands. In case any violation is found your account will be blocked and earnings frozen.",
          ],
        },
      ],
    },
    {
      title: "USER GENERATED CONTENT",
      desc: [
        {
          text: "We may provide various open communication tools on our website, such as blog comments, posts, photos, videos, courses, public chat, forums, message boards, newsgroups, reviews, various social media services, etc. You understand that we do not pre-screen content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website (“User Generated Content”), then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that upload, post, share, or otherwise distribute any content that.",
        },
      ],
    },
    {
      title:
        "OWNERSHIP, PROPRIETORY INFORMATION AND INTELLECTUAL PROPERTY RIGHTS",
      desc: [
        {
          text: "You agree that the Platform including the graphics, our trademarks and editorial content contains proprietary content, information, and material, which are owned by GROMO and/or our licensors, including our customers, brands and agencies and partners, and are protected by applicable intellectual property and other laws, including but not limited to copyright.",
        },
        {
          text: "All content and materials available on GROMO, including but not limited to text, graphics, website name, code, images and logos are either the intellectual property of GROMO or under licensed use by Vitrak, and are protected. Any inappropriate, unauthorized, or prohibited use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized.",
        },
      ],
    },
    {
      title: "CONTRIBUTIONS TO GROMO",
      desc: [
        {
          text: "By submitting ideas, suggestions, documents, and/or proposals (“Contributions”) to GROMO through its suggestion or feedback webpages, you acknowledge and agree that:",
          pointsType: "a",
          points: [
            "your Contributions do not contain confidential or proprietary information,",
            "GROMO is not under any obligation of confidentiality, express or implied, with respect to the Contributions;",
            "GROMO shall be entitled to use or disclose (or choose not to use or disclose) such  Contributions for any purpose, in any way, in any media worldwide,",
            "GROMO may have something similar to the Contributions already under consideration or in development,",
            "your Contributions automatically become the property of GROMO without any obligation of GROMO to you, and",
            "you are not entitled to any compensation or reimbursement of any kind from GROMO under any circumstances.",
          ],
        },
      ],
    },
    {
      title: "INDEMNITY",
      desc: [
        {
          text: "Users agree to indemnify and hold GROMO, its parent company, officers, subsidiaries, affiliates, successors, assigns, directors, officers, agents, service providers, suppliers and employees (“Indemnitees”), harmless from any claim or demand, including penalty, reasonable attorney fees and court costs, made by any third party due to or arising out of (i) content submitted by the User, (ii) Users’ use of the service, (iii) violation of the Terms of Service or Privacy Policy, (iv) breach by the user of any of the Terms, representations and warranties herein, (v) the negligence, fraud, or willful misconduct of User, User’s agents, or contractors; (vi) incorrect information provided by User in User’s Account or elsewhere; or (vii) a failure by User or User’s agents, contractors, or invitees to comply with applicable laws and regulations.",
        },
      ],
    },
    {
      title: "NO RESALE OF SERVICE",
      desc: [
        {
          text: "Under no condition, you should collect any money or benefit from any person, including a User-Client on the pretext of providing services through the Resources or using the name of `GROMO` or its affiliates, partners, advisors, directors or employees. In case of breach of trust, you will be liable to return such money to such person or User-Client  immediately and indemnify GROMO. GROMO shall have the right to disable your Account with immediate effect.",
        },
      ],
    },
    {
      title: "DEALINGS WITH THIRD PARTIES",
      desc: [
        {
          text: "The Platform may also include access to products and services of independent third parties either directly or via links to sites operated by such third parties. Where these products and services of third parties form part of the Platform, we will endeavor, but are not obliged to, indicate that these products and services are provided by third parties. In  all cases, your correspondence or business dealings with, or participation in promotions of, other parties found on or through the Platform (including without limitation providers of products and services, advertisers and other users of the Platform), including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such third party, even where it is in relation to any products or services that are co-branded with us which may include our trademarks. You agree that we shall not be responsible or liable in any way for any loss or damage of any sort incurred as the result of any such dealings with any third parties, as the result of the presence of such third parties on the Platform, or as the result of the use of the Service in any way by such third parties.",
        },
      ],
    },
    {
      title: "LINKS",
      desc: [
        {
          text: "The Platform may provide, or third parties may provide, links to other World Wide Web or other online electronic sites or resources. You acknowledge that GROMO has no control over such sites and resources, you acknowledge and agree that GROMO is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that GROMO shall not be responsible or liable, in any manner whatsoever, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such, goods or services available on or through any such site or resource.",
        },
      ],
    },
    {
      title: "PAYMENT",
      textType: "1",
      desc: [
        {
          text: "Access to Platform: Access to our Platform and Services are currently provided to you at no cost to you.   In the event that we change this in the future, we will communicate such modification to you.",
        },
        {
          text: "Payment for Content: Notwithstanding anything to the contrary herein, you will be responsible for paying the Fees for the Content offered on our Platform offered by our Brand Partners. By purchasing any Content offered on the Platform, you are granted a non- exclusive and non-transferable right to access, view and use the relevant Content and may not use or reproduce any of the Content in any manner, unless expressly authorised by GROMO. For the purposes of these Terms of Service, “Content” shall mean any content, courses, instruction, or other material made available to you over our Platform under by GROMO or a Content Partner. For the purposes of these Terms of Service, a “Content Partner” shall mean any entity authorized by GROMO to make Content available to Users over the Platform. You may pay for the Content on our App by using any legally valid method of payment as accepted by the Platform.",
        },
      ],
    },
    {
      title: "USER EARNINGS",
      textType: "1",
      desc: [
        {
          text: "Commissions: Users can earn commissions on our App (“User Earnings”) by providing to their User-Clients,  through our App or Platform, services affiliated to or authorized by our Brand Partners. User Earnings shall be reflected and credited to the User’s Account on the App once GROMO receives verified updates (“Update(s)”) from our Brand Partners or financial institutions to credit such User Earnings to a User’s Account on the App. If GROMO doesn’t receive the Update from the Brand Partners, it shall not update User Earnings. Under no circumstances can GROMO be held liable for any deficiency in the User Earnings or the non-payment thereof if such a claim arises from the non-receipt of an Update as required by GROMO.",
        },
        {
          text: "Transfer of User Earnings: Users must register a bank account on the App or Platform and provide valid bank account and KYC details to be eligible to transfer their User Earnings to such bank accounts. Users can transfer all their accumulated User Earnings, in whole or in parts, to their registered bank account by clicking on the “Transfer Now” button on User’s Account on the App (“Transfer Request”). The transfer of User Earnings to the registered bank account of the User shall happen within 24-48 hours of the Transfer Request.",
        },
        {
          text: "Any earnings on GroMo will expire if not transferred to your bank account or used within 3 months. For example, if you earn ₹700 on June 8, 2024, and do not transfer or use it for an in-app purchase, the amount will expire at the end of September (September 30, 2024). Expiry always occurs at the end of the upcoming 3rd month."
        },
        {
          text: "Failed Transfer Request: Any Transfer Request that has been initiated by a User, who has not registered a bank account with the App or Platform or has provided invalid bank account details and KYC details or has not updated their valid bank account details on the Platform or App, shall automatically fail.",
        },
        {
          text: "Limitations: A User must initiate a Transfer Request to a valid and registered bank account no later than three (3) months from when that User Earning was updated or credited to their User Account on the App. Any User Earnings, for which either no Transfer Request has been initiated or there have been no valid bank or KYC details shared with the Platform for a period exceeding three (3) months, shall be deemed “Unused Earnings.” All Unused Earnings are subject to being rolled back or debited from the User’s Account on the App at the sole discretion of GROMO.",
        },
      ],
    },
    {
      title: "GROMO COINS",
      textType: "1",
      desc: [
        {
          text: "GroMo Coins is part of the Rewards Program launched by GroMo that focuses on value creation for GroMo Partners in terms of experience and incentives.",
        },
        {
          text: "GROMO reserves the right to carry on Rewards/Loyalty programs and promotions from time to time & GROMO’s decision on its reward policy at any given point of time will remain final.",
        },
        {
          text: "Gromo Partners will now be able to earn GroMo Coins (virtual currency of GroMo) along with their usual earnings from the application. These GroMo coins will have multiple use cases within the GroMo application that would focus on delivering higher value to the users.",
        },
        {
          text: "Conversion Ratio of GroMo Coins to Rupees is 1% (i.e. 100 GroMo Coins is equal to 1 Rupees). In other words, earning 2000 GroMo Coins is equivalent to earning ₹20."
        },
        {
          text: "Earning GroMo Coins",
          pointsType: "a",
          points: [
            "Sale Payout: Payout equivalent GroMo coins will be earned at each sale i.e. if a Gromo Partner earns ₹2000 by selling a credit card, he/she will also earn 2000 GroMo Coins.",
            "Lead Creation: 50 GroMo Coins will be earned on creation of each lead. This benefit of GroMo Coins will be limited to 10 leads per day.",
            "Training: Watching training videos (webinars, courses) will fetch the GroMo Partner ‘x’ GroMo Coins. The GroMo Coins can be different for different videos.  Check the GroMo Coins before watching any such video (only once per video).",
            "Check Credit Score: 100 Gromo Coins will be earned on checking the credit score of each customer (GroMo Coins will only be credited for unique customers). This benefit of GroMo Coins will be limited to 5 times per day",
            "Check Challan: 100 GroMo Coins will be earned on checking the challan of each customers’ vehicle (GroMo Coins will only be credited for unique vehicles). This benefit of GroMo Coins will be limited to 5 times per day",
            "Lead Streak: 50 GroMo Coins are earned on every lead. On reaching weekly milestones (lead created continuously for 7 day period), Gromo Partners will earn 250 extra Gromo Coins than previous 7th day milestones. 7th Day - 250 GroMo Coins, 14th Day - 500 GroMo Coins, 21st Day - 750 GroMo Coins and so on.",
            "Games: Spinning the Wheel or Rolling the Jackpot can reward the GPs with GroMo Coins (up to 1000 GroMo Coins per spin/roll).",
          ],
        },
        {
          text: "Redemption GroMo Coins",
          pointsType: "a",
          points: [
            "Automatic Sale Redemption: 10% of coin balance (coin balance just before that sale) will be automatically redeemed as equivalent rupees with each sale payout. For eg. if a GroMo Partner has 10000 coin balance and he/she makes a sale of ₹500, the GroMo Partner will earn ₹500 + ₹20 (10% of 10000 = 1000 GroMo Coin equivalent rupees).",
            "Spin the Wheel: 100 coins will be used to spin the wheel once. This will be limited to 5 spins per day",
            "Jackpot Roll: 500 coins will be used to roll the jackpot once. This will be limited to 3 rolls per day",
            "GroMo Bazaar: GroMo Partners can get huge discounts on GroMo bazaar by using coins."
          ],
        },
        {
          text: "GroMo Coins expire after 3 months. For example, if a GroMo Partner earned 2000 GroMo Coins on 12th May 2024, they will expire by 31st August 2024."
        },
        {
          text: "GroMo Partners can prevent unutilized coins from expiring by continuing to sell products as every time a sale is made, 10% of coin balance is redeemed in FIFO (First In First Out) basis. This means that the coins which are closer to expiry would be redeemed first.",
        },
        {
          text: "GROMO from time to time can give extra Rewards on different activities, details of which would be available on the GroMo application under the “Rewards” section.",
        },
        {
          text: "GroMo offers additional incentives through scratch cards, which may be either locked or unlocked based on the type of scratch card awarded to the user. Locked scratch cards can only be unlocked by fulfilling the specified unlocking criteria as stated on the locked scratch card. In the case of scratch cards that unlock upon completing a sale (of any kind), the scratch card will only be unlocked if the lead for that sale was generated after the issuance of the scratch card."
        },
        {
          text: "GROMO reserves the right, at its sole discretion, to change the Rewards and modify any terms, features, or discontinue the Rewards Program without any prior written notice. Please refer to your GROMO android application, section “Rewards” to review the latest Reward/Loyalty Program.",
        },
        {
          text: "GROMO reserves the right to suspend any account and claw back all past, current and future earnings from GROMO in case of willful misconduct, gross negligence, indecent behavior, customer complaint, charging money from customers, indecent behavior towards any GROMO employee, contractor or sub-contractor, any other fraud or potential fraud by the agent.",
        },
      ],
    },
    {
      title: "TERMINATION",
      desc: [
        {
          text: "We reserve the right to suspend or terminate your account or cease providing you with access to all or part of our Platform, Website, or the App if we reasonably believe: (i) you have violated this Agreement and/or our Privacy Policy; (ii) you create risk or possible legal exposure for GROMO; (iii) our provision of our Platform, Website, or the App to you is no longer commercially viable; (iv) there is a breach of security or misuse of the account by you, (v) if any information provided during the registration or Verification process or thereafter proves to be inaccurate, not current or incomplete; (vi) if, in GROMO’s assessment, you have committed any kind of fraud including but not limited to forged documents, identity fraud, willful misconduct, or any other such behavior as noticed by GROMO; (vii) if, in GROMO’s assessment, you have engaged in actions that are fraudulent, negligent or derogatory to GROMO’s interests; (viii) or if in GROMO’s assessment, you have taken money from or shared information and/or data of User-Clients without their consent or knowledge; or (viii) you mis-sell or attempt to mis-sell your services under the name and banner of GROMO; or (ix) you have attempted to harm the good will of the company directly or indirectly, or you have posted any false or incorrect escalation on social media or any other public platform, even if the company believes that the escalation is false or incorrect.",
        },
        {
          text: "We will make reasonable efforts to notify you of such termination by the mobile number and email address associated with your account or the next time you attempt to access your account, depending on the circumstances. In all such cases, in GROMO’s sole discretion, GROMO may (i) terminate Agreement; (ii) limit or completely disable  your license to use or access our Platform, Website, or the App; (iii) Penalize you in a manner consistent with this Agreement and all applicable laws; (iv) freeze and reverse any past earnings you may have had using the Platform; or (v) all of the above.",
        },
      ],
    },
    {
      title: "LIMITATION OF WARRANTIES",
      desc: [
        {
          text: "By using our website, you understand and agree that all Resources we provide are “AS IS” and “AS AVAILABLE”. We do not represent or warrant to you that: (i) the use of our Resources will meet your needs or requirements, (ii) the use of our Resources will be uninterrupted, timely, secure or free from errors or virus, (iii) the information obtained by using our Resources will be accurate or reliable, (iv) any downloads from GROMO will not harm your computer or device in any way, and (v) we have screened or verified any of the information posted herein, unless otherwise specifically so stated on the Website. To the maximum extent permitted by law, GROMO has no liability in relation to or arising out of the Website Information and recommendations. You are responsible for the final choice of your product. Please seek further advice from GROMO or the relevant participating companies before choosing any product or service which you wish to avail for , if you have any doubts or queries about the same. GROMO does not guarantee when or if you will actually acquire the product that you have chosen and does not accept any liability arising out of delay in you acquiring the product you have chosen. You acknowledge and accept that the final issuance of the product is subject to the underwriting norms and sole discretion of the company whose product you have chosen to buy. WE DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. No advice or information, whether oral or written, obtained from us or through our Platform, Website, or the App, will create any warranty or representation not expressly made herein.",
        },
      ],
    },
    {
      title: "LIMITATION OF LIABILITY",
      desc: [
        {
          text: "You expressly understand and agree that in no event GROMO or its contractors, agents, licensors, partners, employees, representatives, suppliers be liable to you for any special, direct, indirect, incidental, consequential, punitive, or exemplary damages (including without limitation loss of business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever) arising out of or relating to this agreement, or which may be incurred by you as a result of using our website or its resources or the content, the services, or any reference site, or your use or inability to use the services. In any event, GROMO or any of its contractors, directors, employees, agents, third party partners, licensors or suppliers shall not be liable for any or all damages, liabilities, losses, costs or consequences thereof arising out of or in relation to usage/Agreement, or the Products or Services, however caused and whether arising in contract, tort including negligence, warranty or otherwise. That any claim against us shall be limited to the amount you paid to GROMO, if any.",
        },
      ],
    },
    {
      title: "GOVERNING LAW",
      desc: [
        {
          text: "This Agreement and the Privacy Policy shall be governed and construed in accordance with the laws of the Republic of India and the courts at New Delhi, India shall have exclusive jurisdiction on all matters and disputes arising out of and relating to the  Platform, Website, App or Services provided therein for any actions not subject to Dispute Resolution and Arbitration provisions as set forth in Section 25.",
        },
      ],
    },
    {
      title: "DISPUTE RESOLUTION AND ARBITRATION",
      desc: [
        {
          text: "Any claim, dispute or difference relating to or arising out of these Terms, and Privacy Policy shall be referred to the arbitration, of a sole arbitrator appointed mutually.  The arbitration shall be subject to the Arbitration and Conciliation Act, 1996 as may be amended from time to time. The seat and venue of arbitration shall be New Delhi, India.  The proceedings shall be undertaken in English.  The arbitration award shall be final and binding on the parties.",
        },
      ],
    },
    {
      title: "RELATIONSHIP OF PARTIES",
      desc: [
        {
          text: "The parties hereto are independent contractors, and nothing contained herein shall be interpreted as creating any relationship other than that of independent contracting parties. The parties shall not be construed as being partners, joint ventures, shareholders, employer/employee, or agent/servant. The User has no power or authority to bind GROMO to any obligation, agreement, debt or liability. The User shall not hold itself out as an agent or representative of GROMO.",
        },
      ],
    },
    {
      title: "REFERRAL POLICY",
      textType: "1",
      desc: [
        {
          "text": "The new Elite Referral Program entitles you to earn ₹10,000 from each referral. As per this referral program which is effective on all referrals who joined on or after 6th March 2025, you will earn ₹100 when your referral completes 1st customer sale within 7 days of signup, ₹1,000 more when your referral reaches Gold level, ₹1,500 more when your referral reaches Platinum level and finally ₹7,400 more when your referral becomes an Elite Gromo Partner"},
        {
          "text": "This new Elite Referral Program replaces all previous referral programs and is effective for all referred GroMo Partners who joined on or after 6th March 2025. Incentives in this program apply to all referring GroMo Partners, irrespective of previous program participation. For example, if your referral who joined on 7th March 2025 reaches Gold after the launch of this program, then you will still earn ₹1000"},
        {
          "text": "All earnings will be in the form of scratch cards. You will always earn ₹100 referral reward as an unlocked scratch card. For referral earrings on Gold, Platinum and Elite levels the referring GroMo Partner also needs to be in Gold or above levels and the referred friend should have earned at least ₹5,000 from sales on GroMo (Insurance sales, GroMo Coins and Scratch Cards earnings are not valid). In all such cases, the referring GroMo Partner will get a locked scratch card which will unlock once all conditions are met."},
        {
          "text": "You will not receive the first sale referral reward if your referral does self sale (sale to self) or sell to any other existing GroMo Partner. Only end customer sales are eligible for the first sale referral reward"},
        {
          "text": "5% lifetime referral commission has been discontinued starting from 16th July 2024. For example, if your referral earned Rs 1,000 through a customer sale, then you will not get Rs 50 (5% of Rs 1,000) now"},
        {
          "text": "Referral policy is not valid in cases where the referral is made to an immediate family member. This means that if an immediate family member has been referred, the respective Gromo Partner will not be entitled to any referral reward or referral lifetime commission income"},
        {
          "text": "Insurance sales done by your referral will not be considered towards any referral reward/incentive for the GroMo Elite Referral Progra"},
        {
          "text": "GroMo reserves the right, at its sole discretion to change the amount of referral award and modify any Terms, features, or discontinue the program without any prior written notice. Please refer to your GroMo android application, section “Refer and Earn” to review the latest incentive policy"},
        {
          "text": "GroMo reserves the right to suspend your account and claw back all your past, current and future earning from GroMo in case of willful misconduct, gross negligence, indecent behavior, customer complaint, charging money from customers, indecent behavior towards any GroMo employee, contractor or sub-contractor, any other fraud or potential fraud by the agent"
        },
      ],
    },
    {
      title: "MODIFICATIONS",
      desc: [
        {
          text: "We reserve the right, at our sole discretion, to change or modify this Agreement at any time. In the event, we modify the terms of this Agreement, such modifications shall be binding on you only upon your acceptance of the modified Agreement. We will inform you about the modifications via email, on the Platform, Website, and the App by posting a modified version of this page, or by a comparable means within a reasonable time period.  Your continued use of the Platform, Website, or the App shall constitute your consent to such changes.",
        },
      ],
    },
    {
      title: "REFUNDS AND CANCELLATION POLICY",
      desc: [
        {
          text: "All in-app purchases are non-refundable; however, in cases where refunds are applicable, they shall be processed in the form of equivalent scratchcards within the GroMo platform. These scratchcards can be redeemed in-app and subsequently withdrawn to a bank account, subject to applicable terms."
        },
        {
          text: "Refunds for transactions that remain pending or have failed shall be processed directly by the payment gateway to the original source of payment. In cases where GroMo receives a previously pending payment, the corresponding service or product shall be provided to the user.",
        },
        {
          text: "GroMo bears no liability for payments made by users to third parties for their products or services. Any disputes arising from such transactions must be resolved directly between the user and the respective third party.",
        },
        {
          text: "Refund processing times are subject to payment gateway and banking policies. Users are advised to allow a period of 5-7 business days for the completion of the refund process. GroMo shall not be held responsible for any delays caused by external financial institutions.",
        },
        {
          text: "For any concerns or queries related to payments or refunds, users may contact contact@gromo.in or call +91 8062159334. GroMo reserves the right to request additional information to process refund requests.",
        },
        {
          text: "GroMo reserves the sole right to amend, modify, or discontinue its refund policy at any time without prior notice. Any decisions made by GroMo concerning refunds shall be final and binding on all users.",
        }
      ]
    }
  ];
export const LANDING_PARTNER_LIST = [
  {
    brandName: "Aspire Fintech Private Limited",
    companyName: "Aspire Fintech Private Limited",
  },
  {
    brandName: "Bajaj Finserv",
    companyName: "Bajaj Finance Ltd.",
  },
  // {
  //   brandName: "CASHe",
  //   companyName: "Bhanix Finance and Investment Limited",
  // },
  {
    brandName: "Fibe",
    companyName: "Early Salary Services Private Limited",
  },
  // {
  //   brandName: "Stashfin",
  //   companyName: "EQX Analytics Private Limited",
  // },
  // {
  //   brandName: "Indialends",
  //   companyName: "GC Web Ventures Pvt. Ltd",
  // },
  {
    brandName: "IIFL Finance Limited",
    companyName: "IIFL Finance Limited",
  },
  {
    brandName: "InCred Financial Services Limited",
    companyName: "InCred Financial Services Limited",
  },
  {
    brandName: "Prefr (Credit Vidya)",
    companyName: "Infocredit Services Private Limited",
  },
  // {
  //   brandName: "Privo",
  //   companyName: "Kisetsu Saison Finance (India) Private Limited",
  // },
  {
    brandName: "Abhiloan",
    companyName: "Knab Finance",
  },
  {
    brandName: "KreditBee",
    companyName: "Krazybee Services Private Limited",
  },
  // {
  //   brandName: "Stucred",
  //   companyName: "KREON FINNANCIAL SERVICES LIMITED",
  // },
  // {
  //   brandName: "Instamoney",
  //   companyName: "Lenden Club",
  // },
  // {
  //   brandName: "Upwards",
  //   companyName:
  //     "Lendingkart Finance Limited (Formerly Known As Aadri Infin Limited)",
  // },
  // {
  //   brandName: "OneTap loan",
  //   companyName: "M/S Dhanadeepa Consultant Pvt Ltd.",
  // },
  {
    brandName: "Moneywide",
    companyName: "MLC Finotech Private Limited",
  },
  // {
  //   brandName: "mpokket",
  //   companyName: "mPokket Financial Services Private Limited",
  // },
  // {
  //   brandName: "Navi Finserv Limited",
  //   companyName: "Navi Finserv Limited",
  // },
  // {
  //   brandName: "Paysense",
  //   companyName:
  //     "Payu Finance India Private Limited (Formerly Known As Sidvik Leasing Pvt. Ltd.)",
  // },
  // {
  //   brandName: "Nira Finance",
  //   companyName: "Shuhari Tech Ventures Private Limited",
  // },
  {
    brandName: "SmartCoin Financials Pvt. Ltd.",
    companyName: "SmartCoin Financials Pvt. Ltd.",
  },
  {
    brandName: "SMFG India Credit Limited",
    companyName:
      "SMFG India Credit Company Limited (Formerly known as Fullerton India Credit Company Limited)",
  },
  {
    brandName: "Tata Capital Financial Services Limited",
    companyName: "Tata Capital Financial Services Limited",
  },
  // {
  //   brandName: "True Balance",
  //   companyName: "True Credits Private Limited",
  // },
  {
    brandName: "Moneyview",
    companyName: "Whizdm Innovations Private Limited",
  },
  {
    brandName: "IDFC Bank",
    companyName: "IDFC First Bank Limited",
  },
  {
    brandName: "IndusInd Bank",
    companyName: "IndusInd Bank Limited",
  },
  // {
  //   brandName: "Neokred Technologies Pvt Ltd",
  //   companyName: "Neokred Technologies Pvt Ltd",
  // },
  // {
  //   brandName: "L&T Finance",
  //   companyName: "L&T Finance",
  // },
];

export const THE_PROCESS_LIST = [
  {
    id: "1",
    title: "Install GroMo app and register",
    img: Earn_in_3Steps_1,
    content: "Register with your mobile number on Gromo App",
  },
  {
    id: "2",
    title: "Attend trainings and share financial product links",
    // img: EarnIn3Step_2,
    img: Earn_in_3Steps_2,
    content:
      "Gain Knowledge on Financial products like Demat A/c, Saving A/c, Loan And more.",
  },
  {
    id: "3",
    title: "Start earning money more than ₹1 Lakh every month",
    // img: EarnIn3Step_3,
    img: Earn_in_3Steps_3,
    content:
      "Sell These financial products to your target customers and earn money",
  },
];
export const FEATURE_LIST = [
  {
    title: "Earn more than ₹1 Lakh every month",
    content:
      "Sell financial products with GroMo and make extra income every month",
    sideIcon: EarnMoreicon,
    staticIcon: Earn,
    alt: "Gromo money application",
  },
  {
    title: "Work from Anywhere & Anytime",
    content:
      "You don't need to go to an office or follow fixed working hours—we are flexible",
    sideIcon: wfhIcon,
    staticIcon: WorkAnywhere,
    alt: "earn extra income",
  },

  {
    title: "Zero Investment Business",
    content:
      "Find customers and earn money online without investment like you always wanted",
    sideIcon: zeroInvestmentIcon,
    staticIcon: ZeroInvestment,
    alt: "Zero Investment Business",
  },
  {
    title: "Instant Payout",
    content:
      "No more waiting for weeks to get your sales earning! Get your online earning the moment your sale is success",
    sideIcon: InstantPayoutIcon,
    staticIcon: InstantPayout,
    alt: "instant payout",
  },
  {
    title: "Attend Trainings",
    content:
      "Get trained by our experts and enroll for online courses to learn how to sell and earn money online",
    sideIcon: EducationalContentIcon,
    staticIcon: EducationalContent,
    alt: "Attend Trainings",
  },
];

export const PRODUCT_LIST = [
  {
    title: "Credit Card",
    icon: CreditCard,
    id: "CreditCard",
    alt: "credit card",
  },
  {
    title: "Savings Account",
    icon: SavingAccount,
    alt: "SavingAccount",
    id: "SavingAccount",
  },
  {
    title: " Demat Account",
    icon: DematAccount,
    id: "DematAccount",
    alt: "DematAccount",
  },
  {
    title: "Loan",
    icon: Loan,
    id: "Loan",
    alt: "Loan",
  },
  {
    title: "Line of Credit",
    icon: Credit,
    id: "Credit",
    alt: "Line of Credit",
  },

  {
    title: "Investment",
    icon: Investment,
    id: "Investment",
    alt: "Investment",
  },
];
export const PRODUCT_ACCOUNT_LIST = [
  {
    id: "CreditCard",
    heading: "Your customer can spend now and pay back when convenient",
    points: [
      "Lifetime free credit card",
      "Exciting rewards, discounts & cashbacks",
      "Complimentary airport lounge access",
    ],
    leftIcon: creditCardIcon,
    alt: "credit card",
    buttonLink: "",
  },
  {
    id: "SavingAccount",
    heading: "Managing everyday finances made super easy",
    points: [
      "Earn up to 7% interest",
      "Open 100% digital account",
      "Free IMPS, NEFT, RTGS transactions",
    ],
    leftIcon: savingAccountIcon,
    buttonLink: "",
    alt: "SavingAccount",
  },

  {
    id: "DematAccount",
    heading: "Ask your customers to invest their money for growing it",
    points: [
      "Free Demat account",
      "100% digital & paperless process",
      "Lowest brokerage",
    ],
    leftIcon: dematAccountIcon,
    buttonLink: "",
    alt: "DematAccount",
  },

  {
    id: "Loan",
    heading: "Customers have unplanned needs? Help them with loans",
    points: [
      "Quick loan disbursal",
      "Low interest rates",
      "Low processing fee",
    ],
    leftIcon: loanIcon,
    buttonLink: "",
    alt: "Loan",
  },
  {
    id: "Line of Credit",
    heading: "Assist your customer in paying for urgent expenses",
    points: [
      "100% digital process",
      "Buy now, pay later",
      "Widely accepted around",
    ],
    leftIcon: creditIcon,
    buttonLink: "",
    alt: "Line of Credit",
  },
  {
    id: "Investment",
    heading: " Help customers in creating wealth with regular investing",
    points: [
      "Invest in Equity, Debt & other funds",
      "Pay @0 commision fees for investing",
      "No account opening fee",
    ],
    leftIcon: investmentIcon,
    buttonLink: "",
    alt: "Investment",
  },
];
export const BRAND_1_LIST = [
  {
    name: "indialends",
    icon: indialends,
    redirectLink: "",
  },
  {
    name: "BajajFinserv",
    icon: BajajFinserv,
    redirectLink: "",
  },

  {
    name: "induslndbank",
    icon: induslndbank,
    redirectLink: "",
  },
  {
    name: "instamoney",
    icon: instamoney,
    redirectLink: "",
  },
  {
    name: "BajajFinserv",
    icon: BajajFinserv,
    redirectLink: "",
  },
  {
    name: "axis_bankLogo",
    icon: axis_bankLogo,
    redirectLink: "",
  },
  {
    name: "5paisaLogo",
    icon: paisaLogo,
    redirectLink: "",
  },
];

export const BRAND_2_LIST = [
  {
    name: "upstoxLogo",
    icon: upstoxLogo,
    redirectLink: "",
  },
  {
    name: "paytmmoneyLogo",
    icon: paytmmoneyLogo,
    redirectLink: "",
  },
  {
    name: "mudrexLogo",
    icon: mudrexLogo,
    redirectLink: "",
  },
  {
    name: "jupiterLogo",
    icon: jupiterLogo,
    redirectLink: "",
  },
  {
    name: "indiaGoldLogo",
    icon: indiaGoldLogo,
    redirectLink: "",
  },
  {
    name: "idfc_firstLogo",
    icon: idfc_firstLogo,
    redirectLink: "",
  },
  {
    name: "freechargeLogo",
    icon: freechargeLogo,
    redirectLink: "",
  },
  {
    name: "abhiloansLogo",
    icon: abhiLoansLogo,
    redirectLink: "",
  }
];
export const MEDIA_LIST = [
  {
    name: "Entrackr",
    icon: media_1,
    redirectLink:
      "https://entrackr.com/2022/09/bharat-focused-fintech-startup-gromo-raises-11-mn/",
  },
  {
    name: "Economic Times",
    icon: media_2,
    redirectLink:
      "https://bfsi.economictimes.indiatimes.com/news/fintech/fintech-platform-gromo-distributes-rs-100-crore-in-payouts-to-partner-agents/107596982",
  },
  {
    name: "Business Standard",
    icon: media_3,
    redirectLink:
      "https://www.business-standard.com/companies/start-ups/fintech-platform-gromo-distributes-rs-100-cr-in-payouts-to-partner-agents-124021000651_1.html",
  },
  {
    name: "Yourstory",
    icon: media_4,
    redirectLink:
      "https://yourstory.com/2024/02/gromo-distributed-rs-100-crore-payouts-to-partner-agents",
  },
  {
    name: "INC42",
    icon: media_5,
    redirectLink:
      "https://inc42.com/buzz/gromo-11-mn-partner-bfsi-players-expand-reach/",
  },
  {
    name: "Livemint",
    icon: media_6,
    redirectLink:
      "https://www.livemint.com/companies/start-ups/fintech-platform-gromo-raises-rs-4-crore-in-seed-funding-1560325453095.html",
  },
  {
    name: "Times of India",
    icon: media_7,
    redirectLink:
      "https://timesofindia.indiatimes.com/venture-capital/fintech-startup-gromo-raises-rs-4-crore/articleshow/69758562.cms",
  },
  {
    name: "Forbes India",
    icon: media_8,
    redirectLink:
      "https://www.forbesindia.com/article/brand-connect/lightspeed-india-announces-the-finalists-of-extreme-entrepreneurs-2019/55117/1",
  },
  {
    name: "Tech Circle",
    icon: media_9,
    redirectLink:
      "https://www.techcircle.in/2019/06/12/livspace-co-founder-others-back-fintech-startup-gromo/",
  },
  {
    name: "Money Control",
    icon: media_10,
    redirectLink:
      "https://www.moneycontrol.com/news/business/fintech-platform-gromo-distributes-rs-100-crore-in-payouts-to-partner-agents-12239051.html",
  },{
    name: "Financial Express",
    icon: media_11,
    redirectLink:
      "https://www.financialexpress.com/business/banking-finance-fintech-platform-gromo-distributes-rs-100-crore-in-payouts-to-partner-agents-3390286/",
  },{
    name: "NDTV Profit",
    icon: media_12,
    redirectLink:
      "https://www.ndtvprofit.com/business/fintech-platform-gromo-distributes-rs-100-crore-in-payouts-to-partner-agents",
  },
  {
    name: "ABP News",
    icon: media_13,
    redirectLink:
      "https://news.abplive.com/business/business-news-live-updates-budget-2024-stock-market-sensex-nifty-rbi-mpc-paytm-investors-keen-on-macroeconomic-announcements-q3-results-1663308",
  },
  {
    name: "PTI",
    icon: media_14,
    redirectLink:
      "https://www.ptinews.com/story/business/Fintech-platform-GroMo-distributes-Rs-100-crore-in-payouts-to-partner-agents/1280854",
  },
  {
    name: "Devdiscourse",
    icon: media_15,
    redirectLink:
      "https://www.devdiscourse.com/article/technology/2815050-gromos-fintech-platform-rewards-partner-agents-with-rs-100-crore-in-payouts",
  },
  {
    name: "MSN India",
    icon: media_16,
    redirectLink:
      "https://www.msn.com/en-in/money/topstories/fintech-platform-gromo-distributes-rs-100-crore-in-payouts-to-partner-agents/ar-BB1i4uIN",
  },
  {
    name: "CXO Today",
    icon: media_17,
    redirectLink:
      "https://cxotoday.com/press-release/fintech-platform-gromo-completes-5-years-of-empowering-bharat-distributes-rs-100-crore-in-partner-earnings/",
  },
];

export const RECORD_LIST = [
  {
    text: "R",
    color: "#3366E8",
    title: "Resilience",
    content:
      "Fall down 7 times, stand up 8. Gear up for a new adventure everyday.",
  },
  {
    text: "E",
    color: "#718DD9",
    title: "Ethics",
    content:
      "Be true to yourself and contribute to building a healthy, safe & empathetic work environment.",
  },
  {
    text: "C",
    color: "#ADBDE9",
    title: "Customer Obsession",
    content:
      "Our customer’s wish is our command. Everything we do, we do for them.",
  },
  {
    text: "O",
    color: "#8DD993",
    title: "Ownership",
    content:
      "We have 100% faith in you. Own all your projects & learn 10x faster.",
  },
  {
    text: "R",
    color: "#39BD45",
    title: "Respect & Appreciation",
    content:
      "Happy employees will do the best work of their lives. Respect & appreciation do go a long way.",
  },
  {
    text: "D",
    color: "#08AC17",
    title: "Discipline",
    content:
      "Lot of passion + Lot of discipline is the magic recipe of success in the book of every famous Founder & CEO. ",
  },
];

export const TEAM_CARDS = [
  {
    name: "Ankit Khandelwal",
    hoverText: "Founder & CEO at GroMo",
    linkedIn: "https://www.linkedin.com/in/ankitkhandelwal1987/",
    img: Ankit,
    hoverText2:
      "IIT Delhi alumni. Serial entrepreneur, got 3 successful exits in the past. ex- COO Unicommerce.  Looks after everything at GroMo.",
  },
  {
    name: "Darpan Khurana",
    hoverText: "Co-Founder at GroMo",
    linkedIn: "https://in.linkedin.com/in/khuranadarpan",
    img: DarpanKhurana,
    hoverText2:
      "IIT Delhi alumni. ex- Snapdeal, Oracle, Housing.com, Wecash. Brings wide range of experience from corporate strategy to tech and product.",
  },
  // {
  //   name: "Rajat yadav",
  //   hoverText:
  //     "Leading Operations at GroMo",
  //   linkedIn: "https://in.linkedin.com/in/rajat-yadav-0b38ab33",
  //   img: dummyImg,
  //   hoverText2:'IIT Delhi alumni. ex-Flipkart, Spinny. Taking care of everything operations at GroMo'
  // },
  // {
  //   name: "Manish Jain",
  //   hoverText:
  //     "Leading Design at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/manishjn/",
  //   img: ManishJain,
  //   hoverText2:''

  // },
  // {
  //   name: "Rajat Gupta",
  //   hoverText:
  //     "Leading Academy at GroMo",
  //   linkedIn: "https://in.linkedin.com/in/rajatswm",
  //   img: RajatGupta,
  //   hoverText2:'IIT Roorkee alumni. An ex-bureaucrat turned fintech enthusiast, brings with him an extensive experience of streamlining processes and launching new products. He is currently leading the GroMo Academy'

  // },
  // {
  //   name: "Ashish Arora",
  //   hoverText:
  //     "Leading Insurance Partnerships at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/ashish-arora-624a5961/",
  //   img: AshishArora,
  //   hoverText2:'17+yrs  experience in Strategy, Planning & Sales domain. Strong sales professional with prior experience in Aviva Life insurance, Renewbuy and Policy bazar.'
  // },
  // {
  //   name: "Anshuman Sahay",
  //   hoverText:
  //     "Leading Program Management at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/anshumansahay/",
  //   img: AnshumanSahay,
  //   hoverText2:'IIT Delhi alumni. An engineer, economist and entrepreneur with experience in management consulting, marketing and operations. Currently leading new initiatives for GroMo and building for the Real Bharat!'
  // },
  // {
  //   name: "Jaya Singh",
  //   hoverText:
  //     "Leading HR at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/jaya-singh-4156b643/",
  //   img: JayaSingh,
  //   hoverText2:'A seasoned employee success professional with 12+ years of experience. Jaya is a peoples person and derives her energy and passion from making employees succeed. She is currently leading People & Culture at GroMo.'
  // },
  // {
  //   name: "Amit Joshi",
  //   hoverText:
  //     "Leading Technology at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/amit-joshi88/",
  //   img: AmitJoshi,
  //   hoverText2:'Bring 14 years of experience in various roles from technical management to delivery. Responsible for end to end delivery of products on cmmil5 companies to startup.'
  // },
  // {
  //   name: "Anand Saxena",
  //   hoverText:
  //     "Leading Marketing at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/anand-saxena-013abb75/",
  //   img: AnandSaxena,
  //   hoverText2:'Brings with him an extensive leadership experience across various facets of marketing ranging from customer engagement to brand management. He has worked with big companies like Max Life Insurance and Bank of America. He is currently heading Brand & Communications at GroMo.'
  // },
  // {
  //   name: "Ankit Awasthi",
  //   hoverText:
  //     "Leading Category at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/ankitawa/",
  //   img: AnkitAwasthi,
  //   hoverText2:'A consultant turned bureaucrat, who returned to the start-up world to experience first hand the growth story of India. Currently building categories for GroMo and heading partnerships.'
  // },
  // {
  //   name: "Atul Choudhary",
  //   hoverText:
  //     "Leading Marketing at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/atul-iitd",
  //   img: AtulChoudary,
  //   hoverText2:'Marketing professional who is also an Engineer, Entrepreneur & Educator. Diverse experience of managing growth channels with a problem solving mindset. Now, heading Growth and Acquisition at GroMo.'
  // },
  // {
  //   name: "Rajender Arya",
  //   hoverText:
  //     "Leading Finance at GroMo",
  //   linkedIn: "https://in.linkedin.com/in/rajender-arya-37b0114a",
  //   img: RajenderArya,
  //   hoverText2:'Finance professional with 13+ years of experience in handling end to end finance, due diligence, fund raising, taxation and compliances. Raj is a startup enthusiast having worked in companies like Snapdeal, Boodmo and Bijak'
  // },
  // {
  //   name: "Rajesh Gupta",
  //   hoverText:
  //     "Leading General Insurance at GroMo",
  //   linkedIn: "",
  //   img: RajeshGupta,
  //   hoverText2:''
  // },
  // {
  //   name: "Omkar",
  //   hoverText:
  //     "Leading Program Management at GroMo",
  //   linkedIn: "https://www.linkedin.com/in/omkar-chandragiri-3296ba82/",
  //   img: omkar,
  //   hoverText2:''
  // },
];

export const INVESTOR_ICONS = [
  {
    img: investor1,
  },
  {
    img: investor2,
  },
  {
    img: investor3,
  },
  {
    img: investor4,
  },
  {
    img: investor5,
  },
];

export const INVESTORS_IMAGES = [
  {
    img: investorImg1,
  },
  {
    img: investorImg2,
  },
  {
    img: investorImg3,
  },
  {
    img: investorImg4,
  },
  {
    img: investorImg5,
  },
  {
    img: investorImg6,
  },
  {
    img: investorImg7,
  },
];
